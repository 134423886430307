import { useState, useRef, useEffect, Children } from 'react';

import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Pause from '@mui/icons-material/Pause';
import VolumeMute from '@mui/icons-material/VolumeMute';
import VolumeOff from '@mui/icons-material/VolumeOff';

import { Maybe } from '@/types';
import { Asset, FocalPointImage as TFocalPointImage, Video } from '@/types/generated';

import YouTubeVideoEmbed from '../YouTubeVideoEmbed';
import AspectRatioPaddingBox from '../AspectRatioPaddingBox';
import { getImageWrapperStyles } from '../Service/ServiceMedia/ServiceMedia.styles';

import {
	richTextVideoControlsWrapperStyles,
	videoControlIconStyles,
	videoControlsWrapperStyles,
	getVideoStyles,
	youtubeVideoStyles,
} from './VideoContent.styles';

interface IVideoContent {
	posterImage?: Maybe<Asset>;
	focalPointPosterImage?: Maybe<TFocalPointImage>;
	video?: Maybe<Asset>;
	videoElSx?: React.CSSProperties;
	videoControlsSx?: React.CSSProperties;
	controllableVideo?: Maybe<Video>;
	children?: React.ReactNode;
	dataTestId?: string;
	richTextStylePosition?: string;
	loadVideoInPreviewMode?: boolean;
	fromHero?: boolean;
	sixteenByNineVideoRatio?: boolean;
}

export default function VideoContent({
	video,
	controllableVideo,
	posterImage,
	videoElSx = {},
	children,
	dataTestId = 'video_content',
	richTextStylePosition,
	videoControlsSx = {},
	loadVideoInPreviewMode = false,
	fromHero = false,
	sixteenByNineVideoRatio = false,
}: IVideoContent) {
	const {
		autoPlay = false,
		isLooped = true,
		isMuted = true,
		media,
		posterMedia,
		posterUrl,
		youtubeId,
		useOriginalVideoSize = false,
	} = Object(controllableVideo) as Video;
	const [isVideoReady, setIsVideoReady] = useState(false);
	const [isPlaying, setIsPlaying] = useState<boolean | null>(autoPlay ? true : false);
	const [isNowMuted, setIsNowMuted] = useState<boolean | null>(autoPlay ? true : isMuted);
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
	const isWideScreen = useMediaQuery('(min-width:1615px)');

	const imageWrapperSx = getImageWrapperStyles(true);

	const videoUrl = media?.url || video?.url || '';

	const [userPaused, setUserPaused] = useState(false);

	useEffect(
		function handleVideoEvents() {
			const currentVideoRef = videoRef.current;
			const loopStartTime = 0;
			const loopEndTime = 3;

			function handleVideoEnd() {
				if (currentVideoRef && !isLooped) {
					currentVideoRef.currentTime = loopStartTime;
				}
			}

			function handleVideoReady() {
				setIsVideoReady(true);
			}

			function handleVideoPlaying() {
				setIsPlaying(true);
			}

			function handleVideoPaused() {
				setIsPlaying(false);
			}

			function handleTimeUpdate() {
				if (loadVideoInPreviewMode && currentVideoRef && currentVideoRef.currentTime >= loopEndTime) {
					currentVideoRef.currentTime = loopStartTime;
					setIsPlaying(true);
				}
			}

			if (currentVideoRef) {
				currentVideoRef.addEventListener('ended', handleVideoEnd);
				currentVideoRef.addEventListener('play', handleVideoPlaying);
				currentVideoRef.addEventListener('pause', handleVideoPaused);
				currentVideoRef.addEventListener('oncanplaythrough', handleVideoReady);
				currentVideoRef.addEventListener('timeupdate', handleTimeUpdate);
			}

			return () => {
				if (currentVideoRef) {
					currentVideoRef.removeEventListener('ended', handleVideoEnd);
					currentVideoRef.removeEventListener('play', handleVideoPlaying);
					currentVideoRef.removeEventListener('pause', handleVideoPaused);
					currentVideoRef.removeEventListener('oncanplaythrough', handleVideoReady);
					currentVideoRef.removeEventListener('timeupdate', handleTimeUpdate);
				}
			};
		},
		[isLooped, loadVideoInPreviewMode]
	);

	useEffect(
		function handleScrollInOutOfView() {
			const currentVideoRef = videoRef.current;
			const options = { rootMargin: '0px', threshold: [0.25, 0.75] };

			function handleScroll(entries: { isIntersecting: boolean }[]) {
				entries.forEach((entry) => {
					if (entry.isIntersecting && !userPaused) {
						void currentVideoRef?.play();
					} else {
						void currentVideoRef?.pause();
					}
				});
			}
			function handleVideoReady() {
				setIsVideoReady(true);
			}

			if (!youtubeId) {
				const observer = new IntersectionObserver(handleScroll, options);
				observer.observe(currentVideoRef as HTMLVideoElement);
				handleVideoReady();
				return () => {
					observer.disconnect();
				};
			}
		},
		[isVideoReady, youtubeId, userPaused]
	);

	function handleVideoControlClick() {
		if (videoRef.current) {
			if (isPlaying) {
				videoRef.current.pause();
				setUserPaused(true);
			} else {
				void videoRef.current.play();
				setUserPaused(false);
			}
		}
	}

	function handleMuteUnmute() {
		if (videoRef.current) {
			if (isNowMuted) {
				videoRef.current.muted = false;
				setIsNowMuted(false);
			} else {
				videoRef.current.muted = true;
				setIsNowMuted(true);
			}
		}
	}

	let hasSourceElements = false;
	Children.forEach(children, (c) => {
		if (!c) {
			return;
		}

		if ((c as React.ReactElement)?.type === 'source') {
			hasSourceElements = true;
		}
	});

	if (youtubeId) {
		return (
			<AspectRatioPaddingBox
				aspectRatio={isSmallScreen ? (sixteenByNineVideoRatio ? '16:9' : '1:1') : '16:9'}
				placeholderColor="transparent"
				imageWrapperSx={imageWrapperSx}
				paddingBoxSx={youtubeVideoStyles(richTextStylePosition)}
			>
				<YouTubeVideoEmbed
					youTubeVideoId={youtubeId}
					isSmallScreen={isSmallScreen}
					isBorderRadius={false}
					youTubeVideoImage={posterMedia}
					autoPlay={autoPlay}
					isLooped={isLooped}
					isMuted={isMuted}
					fromHero={fromHero}
					videoControlsSx={videoControlsSx}
					sixteenByNineVideoRatio={sixteenByNineVideoRatio}
				/>
			</AspectRatioPaddingBox>
		);
	} else
		return (
			<>
				<video
					playsInline
					ref={videoRef}
					controls={false}
					muted={!!isNowMuted}
					loop={!!isLooped}
					autoPlay={!!autoPlay}
					style={{ ...videoElSx, ...getVideoStyles(useOriginalVideoSize) }}
					disablePictureInPicture
					data-test-id={dataTestId}
					src={!hasSourceElements ? videoUrl : undefined}
					poster={posterUrl || posterMedia?.url || posterImage?.url || ''}
					onCanPlayThrough={() => setIsVideoReady(true)}
					id={controllableVideo?.sys?.id || ''}
				>
					{children}
				</video>

				{isVideoReady && !useOriginalVideoSize && (
					<Box
						data-test-id={`group_video_controls_for_${controllableVideo?.sys?.id || ''}`}
						sx={[
							videoControlsWrapperStyles(fromHero, isExtraLargeScreen, isWideScreen),
							videoControlsSx,
							richTextStylePosition ? richTextVideoControlsWrapperStyles(isExtraSmallScreen) : {},
						]}
					>
						<IconButton onClick={handleVideoControlClick} aria-label={isPlaying ? 'pause' : 'play'}>
							{isPlaying ? (
								<Pause data-test-id="button_icon_video_pause" sx={videoControlIconStyles} />
							) : (
								<PlayArrow data-test-id="button_icon_video_play" sx={videoControlIconStyles} />
							)}
						</IconButton>

						<IconButton onClick={handleMuteUnmute} aria-label={isNowMuted ? 'Volume on' : 'Mute'}>
							{isNowMuted ? (
								<VolumeOff data-test-id="button_icon_audio_off" sx={videoControlIconStyles} />
							) : (
								<VolumeMute data-test-id="button_icon_audio_on" sx={videoControlIconStyles} />
							)}
						</IconButton>
					</Box>
				)}
			</>
		);
}
